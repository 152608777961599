import React from "react";
import { Menubar } from 'primereact/menubar';
import { Route, Redirect } from "react-router-dom";
import Receipt from './Receipt'
import Report from './Report'
import VoucherEntry from './VoucherEntry'
import ContentEditor from './ContentEditor'
import Company from './Company'
import User from './User'
import Role from './Roles'
import './flexGrid.css';
import './Style.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Carousel from 'react-bootstrap/Carousel'
import { Navbar } from 'react-bootstrap'
import slide1 from "../Templates/assets/img/slide/slide-1.jpg"
import slide2 from "../Templates/assets/img/slide/slide-2.jpg"
import slide3 from "../Templates/assets/img/slide/slide-3.jpg"
class adminHome extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            receiptVisible: false,
            windowType: 0,
            reportVisible: false,
            voucherVisible: false,
            contentVisible: false,
            companyVisible: false,
            userVisible: false,
            roleVisible: false
            
        }

    }
    componentWillMount() {

        if (this.props.location.state === undefined) {

            window.location.href = "/";

        }
    }



    showReceipt = () => {
        this.setState({ receiptVisible: true, windowType: 0 })
    }
    showReport = () => {
        this.setState({ reportVisible: true, windowType: 1 })
    }
    showVoucher = () => {
        this.setState({ voucherVisible: true, windowType: 2 })
    }
    showContent = () => {
    this.setState({ contentVisible: true, windowType: 3 })
    }

    showCompany = () => {
        this.setState({ companyVisible: true, windowType: 4 })
    }

    showUser = () => {
        this.setState({ userVisible: true, windowType: 5 })
    }
    showRole = () => {
        this.setState({ roleVisible: true, windowType: 6 })
    }

    LogOut = () => {
        this.props.history.push("/")
       
    }

    hideWindow = () => {
        if (this.state.windowType === 0) {
            this.setState({ receiptVisible: false })
        }
        else if (this.state.windowType === 1) {
            this.setState({ reportVisible: false })
        }
        else if (this.state.windowType === 2) {
            this.setState({ voucherVisible: false })
        }
        else if (this.state.windowType === 3) {
            this.setState({ contentVisible: false })
        }
        else if (this.state.windowType === 4) {
            this.setState({ companyVisible: false })
        }
        else if (this.state.windowType === 5) {
            this.setState({ userVisible: false })
        }
        else if (this.state.windowType === 6) {
            this.setState({ roleVisible: false })
        }
    }

    render() {
        const items = [{ label: 'Home', icon: 'pi pi-fw pi-home', url: '/' },
        {
            label: 'Invoice', icon: 'pi pi-money-bill', command: () => { this.showReceipt(); }

        },


        {
            label: 'Voucher Entry ', icon: 'pi pi-file-pdf', command: () => { this.showVoucher(); }
        },

        //{
        //    label: 'Content Editor ', icon: 'pi pi-file-pdf', command: () => { this.showContent(); }
        //}
        , {
            label: 'Reports ', icon: 'pi pi-file-pdf', command: () => { this.showReport(); }
            },
        {
            label: 'Settings ',

            items: [

                //{ label: 'Company', icon: 'pi pi-fw pi-cog', command: () => { this.showCompany(); } },
                //{ label: 'User', icon: 'pi pi-fw pi-power-off', command: () => { this.showUser(); } },
                //{ label: 'Role', icon: 'pi pi-fw pi-power-off', command: () => { this.showRole(); } },
                { label: 'Logout', icon: 'pi pi-fw pi-power-off', url: '/' }
            ],

             }
            
        ]

        return (
            <Route exact path="/">{this.props.location.state ?
                <div>
                    <Navbar style={{ backgroundColor: "#a9bacc" }} className="sticky-nav">
                        <Menubar style={{ backgroundColor: "#a9bacc", border: "none" }} model={items}>
                        </Menubar>
                    </Navbar>
                    <Carousel>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={slide1}
                                alt="First slide"
                            />

                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={slide2}
                                alt="First slide"
                            />

                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={slide3}
                                alt="First slide"
                            />

                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={slide3}
                                alt="First slide"
                            />
                            <Carousel.Caption class="slideCaption">
                                <div class="container">
                                    <div class="carousel-content animated fadeInUp">
                                        <h2>Welcome to <span>Company</span></h2>
                                        <p>Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.</p>
                                        <div class="text-center"><a href="" className="btn-get-started">Read More</a></div>
                                    </div>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={slide3}
                                alt="First slide"
                            />

                        </Carousel.Item>
                    </Carousel>
                    {this.state.receiptVisible ? < Receipt loginUser={this.props.location.state.user} visible={this.state.receiptVisible} onHide={this.hideWindow} /> : ''}
                    {this.state.reportVisible ? < Report loginUser={this.props.location.state.user} visible={this.state.reportVisible} onHide={this.hideWindow} /> : ''}
                    {this.state.voucherVisible ? < VoucherEntry loginUser={this.props.location.state.user} visible={this.state.voucherVisible} onHide={this.hideWindow} /> : ''}
                    {this.state.contentVisible ? < ContentEditor loginUser={this.props.location.state.user} visible={this.state.contentVisible} onHide={this.hideWindow} /> : ''}
                    {this.state.companyVisible ? < Company visible={this.state.companyVisible} onHide={this.hideWindow} /> : ''}
                    {this.state.userVisible ? < User visible={this.state.userVisible} onHide={this.hideWindow} /> : ''}
                    {this.state.roleVisible ? < Role visible={this.state.roleVisible} onHide={this.hideWindow} /> : ''}
                </div>
                : <Redirect to={{ pathname: "/" }} />}
                 </Route>            
       );
    }

}
export default adminHome